class Constant {
  //    static  BASE_URL = "https://policiecue-api.mobileprogramming.net/"
  // static BASE_URL = "https://uatapi.policicue.com/"
  // static BASE_URL = "http://localhost:1051/";
  // static BASE_URL = "https://api.policicue.com/"
  static BASE_URL = "https://policiecue-api.mobileprogramming.net/";
  // static BASE_URL = "http://prod-web-api-env.eba-jchcgvgt.ap-south-1.elasticbeanstalk.com/"
  static DEFAULT_TRANING_TIME_IN_MILLS = 54000000;
}

export default Constant;
