import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./login.css";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import {
  login,
  sendMobileOTP,
  verifyMobileotp,
  resendMobileOtp,
} from "../../../store/actions/auth";
import ForgetPassword from "./ForgetPassword";
import { Redirect } from "react-router-dom";
import OtpInput from "react-otp-input";
import { OTP, resendOTP } from "../../../store/actions/auth";
import { Link, useHistory, useLocation } from "react-router-dom";

// Styling
import "./login.css";
const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

const schema = yup
  .object()
  .shape({
    username: yup
      .string()
      .matches(email_regex, "Email is not valid")
      .required(),
    password: yup.string().min(8).required("Please Enter your password"),
  })
  .required();

const Login = ({ onClicked, showModal }) => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [moboileSec, setMoboileSec] = useState(true);
  const [forgetPassSec, setForgetPassSec] = useState(false);
  const [emailSec, setEmailSec] = useState(true);
  const [optSec, setOtpSec] = useState(false);
  const History = useHistory();
  const User = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const location = useLocation();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const history = useHistory();
  const resendOtp = (e) => {
    e.preventDefault();
    console.log("Resending OTP for:", mobile); // Debugging
    if (emailSec && email) {
      dispatch(resendOTP("", mobile, email));
    }
    if (moboileSec && mobile) {
      dispatch(resendMobileOtp("", mobile, ""));
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  let handleChange = (otp) => {
    setOtp(otp);
  };
  const VerfitysendOTP = (e) => {
    e.preventDefault();
    const phone = mobile;
    // const email=email
    localStorage.getItem("user");
    if (otp.length == 4) {
      setLoading(true);
      dispatch(verifyMobileotp(phone, email, otp)).then((res) => {
        if (res === 1) {
          setLoading(false);
          var now = new Date().getTime();
          var setupTime = localStorage.getItem("setupTime");
          if (setupTime == null) {
            localStorage.setItem("setupTime", now);
          }
          localStorage.setItem("SuperDashboard", "SuperDashboard");
          // history.push("/PosDashboard");

          history.push("/MyProfile");
          window.location.reload();
        } else {
          setLoading(false);
        }
      });
    }
  };
  const validate = (values) => {
    const errors = {};
    const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const fname_regex = /^[a-zA-Z ]+$/;
    const lname_regex = /([a-zA-Z]{1,30})$/;
    const mobile_regex = /^[0-9\b]+$/;
    if (values.email == "") {
      errors.email = "Email is Required!";
    } else if (!email_regex.test(values.email)) {
      errors.email = "Enter Valid Email!";
    }
    if (values.mobile == "") {
      errors.mobile = "Mobile is Required!";
    } else if (!mobile_regex.test(values.mobile)) {
      errors.mobile = "Enter Valid Number!";
    }

    return errors;
  };
  const handleLogin = ({ username, password }) => {
    if (emailSec) {
      setFormErrors(validate({ email }));
      if (!validate({ email }).email) {
        setOtpSec(true);
      }
    }
    if (moboileSec) {
      setFormErrors(validate({ mobile }));
      if (!validate({ mobile }).mobile) {
        // setOtpSec(true);
        dispatch(sendMobileOTP(mobile))
          .then((response) => {
            if (response === 1) {
              setOtpSec(true); // Proceed with OTP verification
            } else {
              console.log("Error sending OTP:", response);
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log("OTP sending failed:", error);
            setLoading(false);
          });
      }
      return;
    }
    setLoading(true);

    if ((username, password)) {
      dispatch(login(username, password))
        .then(() => {
          var now = new Date().getTime();
          var setupTime = localStorage.getItem("setupTime");
          if (setupTime == null) {
            localStorage.setItem("setupTime", now);
          }
          localStorage.setItem("SuperDashboard", "SuperDashboard");
          // history.push("/PosDashboard");
          history.push("/MyProfile");
          window.location.reload();
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  // if (isLoggedIn) {
  //   return <Redirect to="/" />;
  // }

  // const handChange = (e) => {
  //   const { value, name } = e.target;
  //   if (name === "mobileNo") {
  //     setMobile(value);
  //   } else {
  //     setEmail(value);
  //   }
  // };

  const handChange = (e) => {
    const { value, name } = e.target;
    if (name === "mobileNo") {
      setMobile(value);
    } else {
      setEmail(value);
    }
  };

  const handleLabelPositionOnFocus = (e) => {
    const label = e.target.nextElementSibling;
    label.style.top = "-5px";
  };
  const handleLabelPositionOnBlur = (e) => {
    const label = e.target.nextElementSibling;
    label.style.setProperty("top", "-5px", "important");
    label.style.setProperty("background-color", "#fff", "important");
    label.style.setProperty(
      "transform",
      "translate3d(-10%, -90%, 0)",
      "important"
    );
    label.style.setProperty("opacity", "1", "important");
  };

  return (
    <Modal show={showModal} onHide={onClicked}>
      <Modal.Body>
        <div className="headerlog ">
          <div className="modal-block-txt">
            <span className="Register_Pos_login">Login as</span>
          </div>
          <div className="modal-block-close-btn">
            <button
              type="button"
              className="btn text-muted modal-btn-size"
              onClick={onClicked}
            >
              <i className="bi bi-x-circle fas fa-lg modal-btn-icon"></i>
            </button>
          </div>
        </div>

        <div className="align-row mb-3">
          <div className="align-row dishead">
            <div style={{ marginTop: "-0.3rem" }}>
              <span className="remembertext" style={{ marginTop: "1rem" }}>
                <i className="bi bi-person-fill icc"></i>
                {/* <img className="i" src="./images/user.png" alt="" /> */}
              </span>
              <span className="remembertext">Customer</span>
            </div>
            <div>
              <span className="remembertext">
                <i className="fas fa-user-tie" style={{ fonSize: "36px" }}></i>
              </span>
              <span className="remembertext">Employee</span>
            </div>
          </div>
          <div className="disheadb">
            <span className="remembertextb">
              <i
                className="fas fa-user-tie"
                style={{ fonSize: "36px", color: "#0093DD" }}
              ></i>
            </span>
            <span className="remembertextb">POS</span>
          </div>
        </div>
        <div className="modal-block">
          {forgetPassSec && (
            <ForgetPassword
              setEmailSec={setEmailSec}
              setForgetPassSec={setForgetPassSec}
            />
          )}

          <form onSubmit={handleSubmit(handleLogin)}>
            {moboileSec && !optSec && !forgetPassSec ? (
              <div className="car2-form-groupp">
                <input
                  type="number"
                  id="icueRegistrationNo"
                  name="mobileNo"
                  className="car2-form-contl"
                  style={{ width: "350px" }}
                  onChange={handChange}
                  onFocus={handleLabelPositionOnFocus}
                  onBlur={handleLabelPositionOnBlur}
                  // {...register("username")}
                  required
                />
                <label
                  className="ms-3 car2-form-control-placeholderr fs-6 mt-2 text-secondary"
                  for="icueRegistrationNo"
                >
                  Mobile No
                </label>

                <p style={{ color: "red" }}> {formErrors.mobile} </p>
              </div>
            ) : !moboileSec && !optSec && !forgetPassSec ? (
              <>
                <div className="car2-form-groupp">
                  <input
                    type="text"
                    id="icueRegistrationNo1"
                    name="icueRegistrationNo1"
                    className="car2-form-contl"
                    style={{ width: "350px" }}
                    onFocus={handleLabelPositionOnFocus}
                    onBlur={handleLabelPositionOnBlur}
                    {...register("username")}
                    required
                  />
                  <label
                    className="ms-3 car2-form-control-placeholderr fs-6 mt-2 text-secondary"
                    for="icueRegistrationNo1"
                  >
                    Email
                    <br />
                  </label>
                </div>

                <br />
                <p style={{ color: "red" }}> {errors.username?.message} </p>
                <br />
                <div className="car2-form-groupp">
                  <input
                    type="password"
                    id="icueRegistrationNo1"
                    name="icueRegistrationNo1"
                    className="car2-form-contl"
                    style={{ width: "350px" }}
                    onFocus={handleLabelPositionOnFocus}
                    onBlur={handleLabelPositionOnBlur}
                    {...register("password")}
                    required
                  />
                  <label
                    className="ms-3 car2-form-control-placeholderr fs-6 mt-2 text-secondary"
                    for="icueRegistrationNo1"
                  >
                    Password
                    <br />
                  </label>
                </div>
                <br />
                <p style={{ color: "red" }}> {errors.password?.message} </p>
              </>
            ) : null}

            {optSec && (
              <div className="col-lg-12  col-12" style={{ padding: "6%" }}>
                <div className="bg-light pl-4 pr-4 ">
                  <p className="text-center otp-text">
                    Please enter the 4 digit code sent to{" "}
                    {mobile.toString().substring(0, 3) +
                      "xxxx" +
                      mobile.toString().substring(7)}
                  </p>
                  <form>
                    <div className="car2-form-groupp w-100">
                      <div className="car3-form-groupp mt-3 mb-2 w-100">
                        <div className="form-row">
                          <OtpInput
                            value={otp}
                            onChange={handleChange}
                            numInputs={4}
                            isInputNum={true}
                            inputStyle={"inputStyle"}
                            containerStyle={"containerStyle"}
                            separator={<span> </span>}
                          />
                        </div>
                      </div>
                    </div>

                    <button
                      disabled={loading}
                      className="login-btn"
                      type="submit"
                      id="submit"
                      onClick={VerfitysendOTP}
                    >
                      Verify
                    </button>
                    <div className="alignrow ">
                      <span
                        onClick={() => {
                          setMoboileSec(true);
                          setOtpSec(false);
                        }}
                        className="postext"
                      >
                        <i className="bi bi-arrow-left pr-1"></i>
                        Back
                      </span>
                      <span className="poste pr-1 pl-1">|</span>

                      <span
                        onClick={resendOtp}
                        className="postext"
                        style={{ cursor: "pointer" }}
                      >
                        Resend Code
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            )}
            <br />

            {!forgetPassSec && (
              <div className="column">
                {!loading && !optSec ? (
                  <button
                    className="login-btn"
                    type="submit"
                    id="submit"
                    onClick={handleLogin}
                  >
                    {moboileSec ? "Proceed" : "Login"}
                  </button>
                ) : loading ? (
                  <span className="spinner-border spinner-border-md"></span>
                ) : null}
              </div>
            )}
            {!optSec && (
              <div className="alignrow " style={{ paddingTop: "0.6rem" }}>
                {moboileSec ? (
                  <>
                    <a href="/registration_pos">
                      <span className="postext">Want to become a POS?</span>{" "}
                    </a>
                    <span className="postext pr-1 pl-1">|</span>
                    <a
                      onClick={() => {
                        setMoboileSec(false);
                      }}
                      className="postext"
                      style={{ cursor: "pointer" }}
                    >
                      Switch to Password Login
                    </a>
                  </>
                ) : (
                  <>
                    {!forgetPassSec && (
                      <>
                        <a href="#" onClick={() => setForgetPassSec(true)}>
                          <span className="postext">Forgot Password ?</span>{" "}
                        </a>
                        <span className="postext pr-1 pl-1">|</span>
                        <a
                          onClick={() => {
                            setMoboileSec(true);
                          }}
                          className="postext"
                          style={{ cursor: "pointer" }}
                        >
                          Switch to OTP Login
                        </a>
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Login;
